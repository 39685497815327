import { wantedApi } from '@app/common/api/wanted';
import { FilterModule } from '@app/stores/_shared/filterModule';
import { baseApiUrl, requestAPI } from '@app/utils';
import { t } from 'i18next';
import { escape, escapeRegExp, keyBy } from 'lodash-es';
import { makeAutoObservable, toJS } from 'mobx';

import { pushSnackbar as showSnackbar, tokenService } from './helpers';

class wantedInterceptionsStore {
  filterModule = undefined;

  list = [];
  /*
   * account: "61c6bb1976842a0052d2d4b4"
   * comment: "Автобус для тестов"
   * created_at: "2022-07-25T14:23:28.022Z"
   * enable: true
   * id: "62dea7857197825a90135dc4"
   * plate_number: "113AD09"
   * reason: "debtor"
   * updated_at: "2022-08-08T16:38:57.131Z"
   */
  current = {};
  lib = {
    reasons: [],
    classes: [],
    tags: [],
  };
  selectedList = [];
  isLoading = false;
  updatedCount = null;

  constructor() {
    makeAutoObservable(this);

    this.filterModule = new FilterModule(
      {
        page: 0,
        limit: 10,
        sortBy: [],
        search: '',
        reason: [],
        tags: [],
        tagsEmpty: false,
      },
      ({ ...args }) => {
        return this.getList({
          ...args,
          page: [args.search, ...args.reason, ...args.tags].some(Boolean)
            ? 0
            : args.page,
        });
      },
    );
  }

  get classes() {
    // return toJS(this.lib.classes);
    return this.lib.classes.map((val) => {
      return {
        label: t(val.value, { ns: 'lib' }),
        value: val.value,
      };
    });
  }

  get totalItems() {
    return this.filterModule.totalItems;
  }

  get reasons() {
    return this.lib.reasons.map((val) => {
      return {
        label: val.label,
        value: val.value,
      };
    });
  }

  get reasonsList() {
    return this.lib.reasons.reduce((obj, val) => {
      obj[val.value] = val.label;
      return obj;
    }, {});
  }

  get tags() {
    return toJS(this.lib.tags);
  }

  get listTable() {
    return this.list.map((c) => ({
      ...c,
      index: c.id,
      plate_number_action: { id: c.id, label: c.plate_number },
      action: { id: c.id, label: c.plate_number, can: c._can },
    }));
  }

  setIsLoading = (loading) => (this.isLoading = loading);

  setSelectedList = (list) => (this.selectedList = list);

  setUpdatedCount = (count) => (this.updatedCount = count);

  clear = () => {
    this.setList([]);
    this.clearCurrent();
  };

  setCurrent = (wanted) => (this.current = wanted);

  clearCurrent = () => this.setCurrent({});

  getCurrentFromListById = (id) => {
    return (this.current = this.list.filter((wanted) => {
      if (wanted.id === +id) return wanted;
    })[0]);
  };

  getCurrentById = async (id) => {
    const { hasError } = await requestAPI({
      func: async () => await wantedApi.getById(id),
      onLoad: (response) => {
        this.current = response.interceptor_wanted ?? {};
      },
      onErrorMessage: {
        message: 'Ошибка получения ',
        callback: () => this.getCurrentById(id),
      },
      onLoading: this.setIsLoading,
    });
    return hasError;
  };

  setList = (wantedList) => (this.list = wantedList);

  getFilteredParams = (search, reason, tags, tagsEmpty) => {
    let params = {};
    if (search) {
      const manyGrnzSearch = search.split(',').filter(Boolean);
      params = {
        $or: [
          ...(search.match(/^[0-9a-f]{24}$/) ? [{ id: search }] : []),
          ...(manyGrnzSearch?.length
            ? manyGrnzSearch.map((item) => {
                return {
                  plate_number: {
                    $regex: escapeRegExp(item.trim()),
                    $options: 'i',
                  },
                };
              })
            : [
                {
                  plate_number: { $regex: escapeRegExp(search), $options: 'i' },
                },
                { name: { $regex: escapeRegExp(search), $options: 'i' } },
                { comment: { $regex: escapeRegExp(search), $options: 'i' } },
              ]),
        ],
      };
    }
    if (reason?.length) {
      params.reason = {
        $in: reason.map((option) => {
          return option.value;
        }),
      };
    }
    if (tags?.length || tagsEmpty) {
      params = {
        ...params,
        $and: [
          {
            $or: [
              ...(tags?.length > 0
                ? [{ tags: { $in: tags.map((option) => option.label) } }]
                : []),
              ...(tagsEmpty
                ? [{ tags: { $eq: [] } }, { tags: { $exists: false } }]
                : []),
            ],
          },
        ],
      };
    }
    return params;
  };

  getLib = async () => {
    const { hasError } = await requestAPI({
      func: async () =>
        await Promise.all([
          wantedApi.getLibReasons(),
          wantedApi.getLibVehicleClasses(),
          wantedApi.getLibTags(),
        ]),
      onLoad: ([reasons, classes, tags]) => {
        this.lib.reasons = reasons;
        this.lib.classes = classes;
        this.lib.tags = tags;
      },
      onErrorMessage: {
        message: 'Ошибка получения данных ',
        callback: () => this.getLib(),
      },
      onLoading: this.setIsLoading,
    });
    return hasError;
  };

  getList = async ({
    limit,
    page,
    sortBy,
    search,
    reason,
    tags,
    tagsEmpty,
  }) => {
    const params = {
      can: ['update', 'delete'],
      include: ['account'],
      limit: limit,
      offset: (page ?? 0) * (limit || 10),
    };
    if (sortBy) {
      params.sort = sortBy;
    }

    params.filter = this.getFilteredParams(search, reason, tags, tagsEmpty);

    await requestAPI({
      func: async () => await wantedApi.getList({ params }),
      onLoad: (response) => {
        const accounts = keyBy(response.accounts, 'id');
        this.filterModule.totalItems = response.count ?? 0;
        const list = response.interceptor_wanted?.map((wanted) => ({
          ...wanted,
          _can: response.can[wanted.id],
          _account: accounts[wanted.account] || {},
        }));

        this.setList(list);
      },
      onErrorMessage: {
        message: 'Ошибка получения ориентировок ',
        callback: () => this.getList({ limit, page, sortBy }),
      },
      onLoading: this.setIsLoading,
    });
  };

  addItem = async (interceptor_wanted) => {
    const { hasError } = await requestAPI({
      func: async () => await wantedApi.create({ interceptor_wanted }),
      onLoad: () => {
        this.getList({ limit: 10, page: 0 });
      },
      onErrorMessage: {
        message: 'Ошибка добавления ориентировки ',
        callback: () => this.addItem(),
      },
      onLoadMessage: `Ориентировка добавлена`,
      onLoading: this.setIsLoading,
    });
    return hasError;
  };

  updateItem = async (wanted) => {
    const { hasError } = await requestAPI({
      func: async () => await wantedApi.update(wanted),
      onLoad: () => {
        this.getList({ limit: 10, page: 0 });
      },
      onErrorMessage: {
        message: 'Ошибка добавления ',
        callback: () => this.updateItem(),
      },
      onLoadMessage: `Ориентировка добавлена`,
      onLoading: this.setIsLoading,
    });
    return hasError;
  };

  updateList = async (wanted) => {
    const { hasError } = await requestAPI({
      func: async () => await wantedApi.updateList(wanted),
      onLoad: (response) => {
        if (response.some((v) => v.error)) {
          throw Error('Ошибка импорта ориентировок', { cause: response });
        } else {
          this.setUpdatedCount(response.length);
        }
      },
      onError: (e) => {
        console.error(e);
        if (e?.cause) {
          e?.cause.forEach((v) => {
            if (v.error) {
              showSnackbar.error(`Ошибка импорта ориентировок ${v.error}`);
            }
          });
        } else {
          showSnackbar.error(
            `Ошибка импорта ориентировок ${e.message || ''}`,
            () => this.updateList(wanted),
          );
        }
      },
      onLoadMessage: `Ориентировки импортированы`,
      onLoading: this.setIsLoading,
    });
    return hasError;
  };

  deleteItem = async (id) => {
    await requestAPI({
      func: async () => await wantedApi.deleteByIds([id]),
      onLoad: () => {
        this.setList(this.list.filter((wanted) => wanted.id !== id));
      },
      onErrorMessage: {
        message: 'Ошибка удаления ',
        callback: () => this.deleteItem(id),
      },
      onLoadMessage: () => `Ориентировка удалена`,
      onLoading: this.setIsLoading,
    });
  };

  deleteItemByIds = async (id) => {
    await requestAPI({
      func: async () => await wantedApi.deleteByIds(id),
      onLoad: () => {
        this.setSelectedList([]);
        this.getList(this.filterModule.filter);
      },
      onErrorMessage: {
        message: 'Ошибка удаления ',
        callback: () => this.deleteItem(id),
      },
      onLoadMessage: () => `Ориентировки удалены`,
      onLoading: this.setIsLoading,
    });
  };

  deleteItemByTeg = async (tag) => {
    await requestAPI({
      func: async () => await wantedApi.deleteItemByTeg([tag]),
      onErrorMessage: {
        message: 'Ошибка удаления по тегу',
        // callback: () => this.deleteItemByTeg(),
      },
      onLoadMessage: `Теги удалены`,
      onLoading: this.setIsLoading,
    });
  };

  doExport = ({ search, page, limit, reason, tags, tagsEmpty }) => {
    const params = {
      can: ['update', 'delete'],
      include: ['account'],
      limit: this.filterModule.totalItems,
      offset: (page ?? 0) * (limit || 10),
      csv: {
        delimiter: ';',
        charset: 'windows-1251',
      },
    };
    const url = `${baseApiUrl()}/v2/interceptor.wanted.csv`;
    const token = tokenService.getToken();

    params.filter = this.getFilteredParams(search, reason, tags, tagsEmpty);

    const html = `
    <form method="post" action="${url}" target="_blank" id="rtms-number-csv" >
          <img name="img" src="" value=""></img>
            <input type="hidden" name="token" value="${escape(token)}" />
            <input type="hidden" name="json" value="${escape(
              JSON.stringify(params),
            )}" />
          </form>
        `;

    const div = document.createElement('div');
    div.innerHTML = html;

    document.body.appendChild(div);
    document.getElementById('rtms-number-csv').submit();
    document.body.removeChild(div);
  };
}

export { wantedInterceptionsStore };
