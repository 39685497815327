import { inject, observer } from 'mobx-react';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';

import { IconCrossRounded } from '../../../assets';
import iconAttach from '../../../assets/icons/svg/iconAttach.svg';
import { getFileByPath } from '../../../hooks';
import { PreloaderIcon } from '../../_other';
import { CallbackButton } from '../../HOC';

export const InputFile = inject(({ store }) => ({
  token: store.auth.token,
}))(
  observer(
    ({
      onUpload,
      className,
      fileValue,
      isLoading,
      labelText,
      token,
      accept,
    }) => {
      const inputRef = useRef(null);

      const [file, setFile] = useState(null);
      const [isImageFile, setIsImageFile] = useState(null);

      useEffect(() => {
        if (file && inputRef.current) {
          inputRef.current.disabled = true;
        }
      }, [file, inputRef]);

      useEffect(() => {
        if (fileValue) {
          setIsImageFile(getFileByPath(fileValue, token));
          setFile(fileValue);
        }
      }, [fileValue]);

      const onChange = (e) => {
        if (!onUpload) {
          throw Error('Нужен колбэк для инпута :)');
        }
        e.preventDefault();
        if (inputRef.current) {
          const file = inputRef.current?.files[0];
          const isImage = false;
          setIsImageFile(isImage);
          if (isImage) {
            let fileUpload = URL.createObjectURL(file);
            setFile(fileUpload);
          } else {
            setFile(file.name);
          }
          onUpload(inputRef.current?.files[0]);
        }
      };

      const clearFile = (e) => {
        e.preventDefault();
        e.stopPropagation();
        inputRef.current.disabled = false;
        inputRef.current.setAttribute('data-value', '');
        inputRef.current.value = '';
        setFile(null);

        onUpload(null);
      };
      return (
        <div
          className={`relative input-file overflow-hidden ${
            className ? className : ''
          }`}>
          <input
            id="upload-file"
            type="file"
            ref={inputRef}
            onChange={onChange}
            data-value=""
            className={`hidden`}
            accept={accept}
          />
          <label
            htmlFor="upload-file"
            className="min-h-[50px] px-4 gap-2 flex items-center cursor-default focus:outline-black">
            {isLoading && <PreloaderIcon className={'text-accent w-5 h-5'} />}
            {file == null && !isLoading ? (
              <div className={'gap-2 flex items-center '}>
                <img alt={labelText} src={iconAttach} className="w-5 h-5" />
                <span className="text-accent text-reg font-medium ">
                  {labelText}
                </span>
              </div>
            ) : (
              <>
                <div className="flex  items-center justify-center max-h-12 ">
                  {isImageFile ? (
                    <img className="h-12" alt={' '} src={file} />
                  ) : (
                    <span className=" break-all pr-4">{file}</span>
                  )}
                </div>
                <CallbackButton
                  className="ml-auto py-[12px] max-h-12  cursor-pointer"
                  callback={clearFile}>
                  <IconCrossRounded className="" />
                </CallbackButton>
              </>
            )}
          </label>
        </div>
      );
    },
  ),
);
