import { pushSnackbar } from '@app/stores';
import { createError } from '@app/utils';
import { debounce } from 'lodash-es';
import io from 'socket.io-client';

class _socketInstance {
  socket;
  _serverUrl;

  constructor({ onConnect, onDisconnect, onReconnecting, onReconnect, url }) {
    this.onConnect = onConnect;
    this.onDisconnect = onDisconnect;
    this.onReconnecting = onReconnecting;
    this.onReconnect = onReconnect;
    this._serverUrl = url;
  }

  initSocket = async (__init) => {
    return new Promise((resolve) => {
      if (this.socket?.connect) return;
      if (__init) {
        console.debug(__init, 'should init socket instance');
        console.debug(this._serverUrl, 'serverUrl init socket instance');
        if (!this._serverUrl) {
          throw Error('Please provide socket url');
        }
        console.debug(this._serverUrl, 'try to connect by provided url');
        if (!this.socket || !this.socket?.connected) {
          console.debug('not connected socket instance');
          this.socket = io(this._serverUrl, {
            transports: ['websocket'],
            reconnect: 20000,
          });
          console.debug(this.socket, 'created Socket');
          this.socket.on('connect', (e) => {
            this.socket.removeAllListeners('common-event');

            this.onConnect && this.onConnect(e);
            console.debug('SOCKET: ||connected||');
            resolve();
          });
          this.socket.on('connection', (socket) => {
            console.debug(socket, 'SOCKET: ||connection||');
          });
          this.socket.on('disconnect', (err) => {
            this.onDisconnect && this.onDisconnect(err);
            console.debug('SOCKET: ||disconnect||');
          });
          this.socket.on('connect', (e) => {
            this.socket.removeAllListeners('common-event');

            this.onConnect && this.onConnect(e);
            console.debug('SOCKET: ||connected||');
            resolve();
          });
          this.socket.on('reconnecting', (e) => {
            this.onReconnecting && this.onReconnecting(e);
            console.debug('SOCKET: ||reconnection||');
          });
          this.socket.on('reconnect', (e) => {
            this.onReconnect && this.onReconnect(e);
            console.debug('SOCKET: ||reconnected||');
          });
        }
      } else {
        this.socket.disconnect();
      }
    });
  };

  on = async (method, callback) => {
    if (this.socket) {
      let cb = async (param) => {
        await callback(param);
      };
      this.socket.on(method, cb);
    }
  };

  emit = async ({ method, params }) => {
    if (!this.socket?.connect) {
      await this.initSocket(true);
    }
    return new Promise((resolve, reject) => {
      this.socket.emit(method, params, function (err, data) {
        !err
          ? resolve(data)
          : reject(typeof err === 'object' ? JSON.stringify(err) : err);
      });
    });
  };

  showProgress = async (count, total) => {
    const persent = Math.floor((count / total) * 100);

    if (persent < 100) {
      const progressBar = document.createElement('div');
      progressBar.style.width = `${persent}%`;
      progressBar.style.backgroundColor = '#4caf50';
      progressBar.style.height = '20px';
      progressBar.style.top = '10px';
      progressBar.style.left = '10px';
      progressBar.style.zIndex = '99999';
      progressBar.style.position = 'fixed';
      progressBar.classList.add('progress-bar');
      document.body.appendChild(progressBar);
    } else {
      const progressBar = document.querySelector('.progress-bar');
      progressBar && progressBar.parentNode.removeChild(progressBar);
    }
  };

  async bulk({ method, params, chunkBy = 10, withProgress = false }) {
    if (!this.socket?.connect) {
      throw createError('error connect');
    }

    if (!params?.length) return;

    let bulk = params.map((item) => ({
      method: method,
      params: item,
    }));

    const chunkedItems = chunkArray(bulk, chunkBy);

    function chunkArray(array, size) {
      const chunked = [];
      for (let i = 0; i < array.length; i += size) {
        chunked.push(array.slice(i, i + size));
      }
      return chunked;
    }

    const total = bulk.length;
    let count = 0;

    const debouncedNotify = debounce(() => {
      pushSnackbar.success(`Прогресс: ${count}/${total} завершено!`);
    }, 1000);

    const _response = await Promise.allSettled(
      chunkedItems.map(async (bulk) => {
        return new Promise((resolve, reject) => {
          this.socket.emit('bulk.parallel', { bulk }, (err, data) => {
            if (err) {
              reject(typeof err === 'object' ? JSON.stringify(err) : err);
            } else {
              if (withProgress) {
                count += data.results?.filter((r) => !r.error)?.length;
                debouncedNotify();
              }
              resolve(data.results);
            }
          });
        });
      }),
    );

    const ok_results = _response.filter((v) => v.status === 'fulfilled');
    const not_ok_results = _response.filter((v) => v.status === 'rejected');

    if (not_ok_results.length) {
      throw new Error(not_ok_results.map((v) => v.reason).flat(1));
    } else {
      return ok_results.map((v) => v.value).flat(1);
    }
  }

  removeAllListeners = async (event) => {
    await this.socket.removeAllListeners(event);
  };

  destroy = () => {
    this.socket.disconnect();
  };
}

export const socketInstance = _socketInstance;
