import { inject, observer } from 'mobx-react';
import { Component, useEffect } from 'react';

import {
  ErrorSnackbar,
  InfoSnackbar,
  PortalNotifications,
  SuccessSnackbar,
} from '../../components';

export const ENotificationVariants = {
  INFO: 'info',
  SUCCESS: 'success',
  ERROR: 'error',
};

const Notifications = inject(({ store }) => ({
  setCurrentNotificationIndex: store.ui.setCurrentNotificationIndex,
  currentNotificationIndex: store.ui.currentNotificationIndex,
  notifications: store.ui.notifications,
  removeSnackbar: store.ui.removeSnackbar,
}))(
  observer((props) => {
    const {
      setCurrentNotificationIndex,
      currentNotificationIndex,
      notifications,
      removeSnackbar,
    } = props;

    useEffect(() => {
      notifications.every((notification, index) => {
        if (notification.isShow === true && currentNotificationIndex < index) {
          setCurrentNotificationIndex(index);
          return false;
        } else {
          return true;
        }
      });
    }, [notifications?.length]);
    return notifications?.map((notification, index) => {
      const { isShow, variant, message, callbackActionText, callback } =
        notification;
      if (!isShow) return null;
      if (index - currentNotificationIndex < 10) {
        if (variant === ENotificationVariants.ERROR) {
          return (
            <InfoSnackbar
              onClose={() => removeSnackbar(notification.key)}
              key={message + ' ' + index}
              isShow={isShow}
              message={message}
              callbackActionText={callbackActionText}
              callback={callback}
            />
          );
        }
        if (variant === ENotificationVariants.INFO) {
          return (
            <ErrorSnackbar
              onClose={() => removeSnackbar(notification.key)}
              key={message + ' ' + index}
              isShow={isShow}
              message={message}
              callbackActionText={callbackActionText}
              callback={callback}
            />
          );
        }
        if (variant === ENotificationVariants.SUCCESS) {
          return (
            <SuccessSnackbar
              callbackActionText={callbackActionText}
              onClose={() => removeSnackbar(notification.key)}
              key={message + ' ' + index}
              isShow={isShow}
              message={message}
              callback={callback}
            />
          );
        }
        return null;
      }
    });
  }),
);

class _Notifier extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }
  render() {
    const { showNotifications } = this.props;
    return (
      <PortalNotifications isShow={showNotifications}>
        <div
          id="notif-div"
          className={
            'fixed bottom-4 right-4 md:right-8 xl:right-24 transition-all notifier  md:max-w-[50vw]'
          }>
          <Notifications />
        </div>
      </PortalNotifications>
    );
  }
}
const mapMobxToProps = ({ store }) => {
  return {
    showNotifications: store.ui.showNotifications,
  };
};
const NotifierWithStore = inject(mapMobxToProps)(observer(_Notifier));

export const Notifier = NotifierWithStore;
